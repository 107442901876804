import { graphql, Link, useStaticQuery } from 'gatsby';
import { parse } from 'graphql-compose/lib/graphql';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { BiPurchaseTag } from 'react-icons/bi';
import { FcCalendar } from 'react-icons/fc';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Pagination from 'react-responsive-pagination';
import defaultEventPicture from '../../../images/defaultEventPicture.jpg';
import defaultUserProfile from '../../../images/defaultUserProfile.png';
import notFoundPicture from '../../../images/sad.png';
import '../../../styles/blogStyle/style.css';
import '../../common/i18n';
import { useTranslation } from 'react-i18next';

const ArticlesGrid = () => {
  const { t, i18n } = useTranslation();
  const articlesGridData = useStaticQuery(graphql`
    query {
      allStrapiArticles {
        nodes {
          article_author {
            avatar {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            shortDescription
            name
          }
          article_tags {
            name
          }
          slug
          published_at
          roArticle
          title
          markdown
          feature_image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      allStrapiArticleTags {
        nodes {
          name
        }
      }
    }
  `);

  const [currentPage, setCurrentPage] = useState(1);
  const [showLoader, setShowLoader] = useState(false);
  const [activeTags, setActiveTags] = useState([]);
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [anyArticlesToShow, setAnyArticlesToShow] = useState(false);

  const [articles, setArticles] = useState(
    articlesGridData.allStrapiArticles.nodes.sort((a, b) => {
      return new Date(b.published_at) - new Date(a.published_at);
    })
  );

  const filterArticles = (selectedTags) => (a) => {
    if (selectedTags.length === 0) {
      return a;
    } else {
      let isInFilters = false;
      selectedTags.forEach((selectedTag) => {
        a.article_tags.forEach((articleTag) => {
          if (selectedTag === articleTag.name) {
            isInFilters = true;
          }
        });
      });
      if (isInFilters) {
        return a;
      }
    }
    return false;
  };

  const [totalPages, setTotalPages] = useState(
    Math.ceil(articlesGridData.allStrapiArticles.nodes.length / 12)
  );

  const tags = articlesGridData.allStrapiArticleTags.nodes.sort((a, b) => {
    return b.name - a.name;
  });

  const search = ' ';
  const replaceWith = '-';

  useEffect(() => {
    const isBrowser = typeof window !== 'undefined';
    if (isBrowser)
      if (
        localStorage.getItem('savedBlogTags') &&
        localStorage.getItem('savedBlogTags').length > 0
      ) {
        const activeTags = JSON.parse(localStorage.getItem('savedBlogTags'));
        setActiveTags(activeTags);
        setFilteredArticles(articles.filter(filterArticles(activeTags)));
      } else {
        localStorage.setItem('savedBlogTags', '[]');
        setActiveTags([]);
        setFilteredArticles(articles);
      }
    let timer1 = setTimeout(() => setShowLoader(true), 1000);
    return () => {
      clearTimeout(timer1);
    };
  }, []);

  const toggleTag = (tag) => {
    const isBrowser = typeof window !== 'undefined';
    if (isBrowser) {
      let savedArray = null;

      if (anyArticlesToShow) setAnyArticlesToShow(false);
      if (
        localStorage.getItem('savedBlogTags') &&
        localStorage.getItem('savedBlogTags').length > 0
      )
        savedArray = JSON.parse(localStorage.getItem('savedBlogTags'));
      if (!savedArray) {
        localStorage.setItem('savedBlogTags', `["${tag}"]`);
        setActiveTags(savedArray);
      } else {
        if (savedArray.includes(tag)) {
          savedArray = savedArray.filter((e) => e !== tag);
        } else {
          savedArray.push(tag);
        }
        localStorage.setItem('savedBlogTags', JSON.stringify(savedArray));
        setActiveTags(savedArray);
        setFilteredArticles(articles.filter(filterArticles(savedArray)));
      }
    }
  };

  useEffect(() => {
    setTotalPages(Math.ceil(filteredArticles.length / 12));
  }, [filteredArticles]);

  const isRightLang = (article) => {
    const isBrowser = typeof window !== 'undefined';
    if (isBrowser) {
      if (localStorage.getItem('lang') === 'ro' && article.roArticle) {
        if (!anyArticlesToShow) setAnyArticlesToShow(true);
        return true;
      } else if (localStorage.getItem('lang') === 'en' && !article.roArticle) {
        if (!anyArticlesToShow) setAnyArticlesToShow(true);
        return true;
      }
      return false;
    }
    return false;
  };

  useEffect(() => {
    setAnyArticlesToShow(false);
  }, [localStorage.getItem('lang')]);

  const notFound = () => {
    return (
      <div
        class="col-lg-12 col-md-12"
        style={{
          marginTop: '12vh',
          opacity: 0.6,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <img
          src={notFoundPicture}
          style={{
            maxHeight: '15vh',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        ></img>
        <h2 style={{ textAlign: 'left', fontSize: '3rem' }}>
          {t('articlesGridComponent.notFound')}
        </h2>
      </div>
    );
  };
  return (
    <>
      {!showLoader && (
        <div className="loader-container">
          {' '}
          <Loader
            type="Grid"
            color="#D50000"
            height={100}
            width={100}
            timeout={1000}
            style={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          />
        </div>
      )}

      <div style={{ marginTop: '105px' }} />

      <div className="blog-area ptb-100">
        <div className="container" style={{ minHeight: '90vh' }}>
          <div className="row">
            {' '}
            <h1 className="title">{t('articlesGridComponent.title')}</h1>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 mb-3">
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <h2>
                  <BiPurchaseTag
                    style={{
                      margin: '5px 5px 0px 15px',
                      height: '30px',
                      width: '30px',
                    }}
                  />
                </h2>
                {tags?.length > 0 &&
                  tags.map((tag, i) => {
                    return (
                      <div
                        style={{ display: 'flex', flexWrap: 'wrap' }}
                        key={i}
                      >
                        <h2
                          className={
                            activeTags?.includes(tag.name)
                              ? 'active-tags'
                              : 'tags'
                          }
                          onClick={() => toggleTag(tag.name)}
                        >
                          {tag.name + ' '}
                        </h2>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="row">
            {filteredArticles?.length > 0 &&
              [...Array(12)].map(
                (x, i) =>
                  filteredArticles[(currentPage - 1) * 12 + i] &&
                  isRightLang(filteredArticles[(currentPage - 1) * 12 + i]) && (
                    <div key={i} className="col-lg-4 col-md-6">
                      <div className="single-blog-post bg-fffbf5">
                        <div className="post-image">
                          <Link
                            to={`/article/${
                              filteredArticles[(currentPage - 1) * 12 + i].slug
                            }`}
                          >
                            <img
                              src={
                                filteredArticles[(currentPage - 1) * 12 + i]
                                  .feature_image
                                  ? filteredArticles[(currentPage - 1) * 12 + i]
                                      .feature_image.childImageSharp.fluid.src
                                  : defaultEventPicture
                              }
                              alt="image"
                            />
                          </Link>
                        </div>
                        <div className="post-content">
                          <ul className="post-meta d-flex justify-content-between align-items-center">
                            <li>
                              <div className="post-author d-flex align-items-center">
                                <img
                                  src={
                                    filteredArticles[(currentPage - 1) * 12 + i]
                                      .article_author.avatar
                                      ? filteredArticles[
                                          (currentPage - 1) * 12 + i
                                        ].article_author.avatar.childImageSharp
                                          .fluid.src
                                      : defaultUserProfile
                                  }
                                  className="rounded-circle"
                                  alt="image"
                                />
                                <span>
                                  {
                                    filteredArticles[(currentPage - 1) * 12 + i]
                                      .article_author.name
                                  }
                                </span>
                              </div>
                            </li>
                            <li>
                              <div style={{ paddingBottom: '14px' }}>
                                <FcCalendar />
                                {filteredArticles[
                                  (currentPage - 1) * 12 + i
                                ].published_at.substring(0, 10)}
                              </div>
                            </li>
                          </ul>
                          <h3>
                            <Link
                              to={`/article/${
                                articles[(currentPage - 1) * 12 + i].slug
                              }`}
                            >
                              {
                                filteredArticles[(currentPage - 1) * 12 + i]
                                  .title
                              }
                            </Link>
                          </h3>
                        </div>
                      </div>
                    </div>
                  )
              )}
            {!anyArticlesToShow && notFound()}
            {anyArticlesToShow && (
              <div className="col-lg-12 col-md-12">
                <Pagination
                  current={currentPage}
                  total={totalPages}
                  onPageChange={setCurrentPage}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ArticlesGrid;
